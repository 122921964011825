import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import metrics from "../shared/images/metrics.png";

function TrustCenter() {
  return (
    <>
      <div className="banner trustcenter-banner"></div>
      <div className="heading padding-tb-20">
        <Container>
          <h1>Impartner Privacy Center</h1>
          <h2>
            At Impartner, we care about privacy and believe in providing
            transparency to consumers about our information handling practices.
          </h2>
        </Container>
      </div>
      <Container className="my-5">
        <Row className="ds-flex justify-content-center">
          <Col xs={12} md={6} lg={6}>
            <a
              className="card-tc"
              href="https://www.impartner.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={metrics} width="50px" alt="metrics" />
              <p className="margin-top--10 fw--400">Privacy Policy</p>
            </a>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <a className="card-tc" href="/submit-request">
              <img src={metrics} width="50px" alt="metrics" />
              <p className="margin-top--10 fw--400">
                Submit Data Subject Request
              </p>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TrustCenter;
