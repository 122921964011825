import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TrustCenter from "./components/TrustCenter";
import SubmitRequest from "./components/SubmitRequest";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import Access from "./components/Access";
import Delete from "./components/Delete";
import Optout from "./components/Optout";
import Correction from "./components/Correction";
import AccessPortability from "./components/AccessPortability";
import Rectification from "./components/Rectification";
import Erasure from "./components/Erasure";
import RestrictProcessing from "./components/RestrictProcessing";

import "./App.scss";

function App() {
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <div style={{ paddingBottom: "5rem" }}>
        <Header />
        <Router>
          <Switch>
            <Route path="/" exact component={TrustCenter} />
            <Route path="/submit-request" component={SubmitRequest} />
            <Route path="/USA/access" component={Access} />
            <Route path="/USA/delete" component={Delete} />
            <Route path="/USA/donotsell" component={Optout} />
            <Route path="/USA/correction" component={Correction} />

            <Route
              path="/EU/access-portability"
              component={AccessPortability}
            />
            <Route path="/EU/rectification" component={Rectification} />
            <Route path="/EU/erasure" component={Erasure} />
            <Route
              path="/EU/restrictprocessing"
              component={RestrictProcessing}
            />
          </Switch>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
